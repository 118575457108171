import * as bootstrap from 'bootstrap';

document.addEventListener('turbo:load', () => {
  const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

  tooltipElements.forEach((tooltipElement) => {
    new bootstrap.Tooltip(tooltipElement);
  });

  const modalElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

  modalElements.forEach((modalElement) => {
    new bootstrap.Modal(modalElement);
  });
});
