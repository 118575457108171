import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static targets = ['modal'];

  open() {
    bootstrap.Modal.getOrCreateInstance(this.modalTarget).show();
  }

  close() {}
}
