import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  click() {
    if (this.element.classList.contains('sidenav-toggled')) {
      this.element.classList.remove('sidenav-toggled');
    } else {
      this.element.classList.add('sidenav-toggled');
    }
  }
}
