import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {}

  async validateFiles(event) {
    const files = event.target.files;

    for (const file of files) {
      const isValid = this.validateFileType(file);

      if (!isValid) {
        alert(
          '¡Has subido un formato de archivo no soportado!\nRecuerda solo subir archivos de audio o video.',
        );

        event.target.value = null;

        return;
      } else {
        const isLongEnough = await this.validateAudioDuration(file);

        if (!isLongEnough) {
          alert(
            '¡Has subido un archivo de audio con una duración menor a 25 segundos!\nRecuerda que el tiempo mínimo permitido es de 25 segundos.',
          );

          event.target.value = null;

          return;
        }
      }
    }
  }

  validateFileType(file) {
    const validTypes = ['audio', 'video'];
    const fileType = file.type.split('/')[0];

    if (validTypes.indexOf(fileType) === -1) {
      return false;
    } else {
      return true;
    }
  }

  validateAudioDuration(file) {
    const audioUrl = URL.createObjectURL(file);
    const audio = new Audio(audioUrl);

    return new Promise((resolve) => {
      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration < 25) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  disableSubmitButton(event) {
    event.target.classList.add('disabled');
  }
}
