import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'identificationNumberTypeSelect',
    'identificationNumberInput',
    'titleInput',
    'petitionerAttorneyInput',
    'defendantAttorneyInput',
    'reservedCheckbox',
  ];

  connect() {
    if (this.identificationNumberTypeSelectTarget.value === 'iue') {
      this.toggleIueValidation(true);
    }
  }

  toggleIueValidation(enabled) {
    this.validationHandler ||= this.validateIueFormat.bind(this);

    if (enabled) {
      this.identificationNumberInputTarget.addEventListener('input', this.validationHandler);
    } else {
      this.identificationNumberInputTarget.removeEventListener('input', this.validationHandler);
      this.removeValidationState();
    }
  }

  validateIueFormat(event) {
    this.clearFormFields();

    if (event.target.value === '') {
      this.removeValidationState();

      return;
    }

    const isFormatValid = this.isIueFormatValid(event.target.value);

    this.updateValidationState(isFormatValid);

    if (isFormatValid) this.fetchLegalCaseInformation();
  }

  isIueFormatValid(value) {
    const iueRegex = /^\d+-\d+\/\d{4}$/;

    return iueRegex.test(value);
  }

  updateValidationState(isValid) {
    const classList = this.identificationNumberInputTarget.classList;

    classList.remove('is-valid', 'is-invalid');
    classList.add(isValid ? 'is-valid' : 'is-invalid');
  }

  removeValidationState() {
    this.identificationNumberInputTarget.classList.remove('is-valid', 'is-invalid');
  }

  clearFormFields() {
    this.titleInputTarget.value = '';
    this.petitionerAttorneyInputTarget.value = '';
    this.defendantAttorneyInputTarget.value = '';
    this.reservedCheckboxTarget.checked = false;
  }

  identificationNumberTypeSelectChanged(event) {
    this.identificationNumberInputTarget.value = '';
    this.clearFormFields();
    this.toggleIueValidation(event.target.value === 'iue');
  }

  fetchLegalCaseInformation() {
    const url = new URL(`/external/expediente`, window.location.origin);
    url.searchParams.append('iue', this.identificationNumberInputTarget.value);

    return fetch(url).then((response) => {
      if (response.ok) {
        this.handleSuccessResponse(response);
      } else if (response.status === 404) {
        this.handleNotFoundResponse(response);
      } else if (response.status === 403) {
        this.handleForbiddenResponse(response);
      }
    });
  }

  handleSuccessResponse(response) {
    response.json().then((data) => {
      this.titleInputTarget.value = data.title;
      this.petitionerAttorneyInputTarget.value = data.petitioner_attorney;
      this.defendantAttorneyInputTarget.value = data.defendant_attorney;
    });
  }

  handleNotFoundResponse() {
    alert('No se encontró el expediente');
  }

  handleForbiddenResponse() {
    alert('El expediente mantiene reserva');

    this.reservedCheckboxTarget.checked = true;
  }
}
