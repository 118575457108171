import { Application } from '@hotwired/stimulus';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };

import Glide from '@glidejs/glide';

addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.glide')) {
    new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      perView: 4,
      autoplay: 2500,
      hoverpause: true,
      animationDuration: 800,
      gap: 30,
      breakpoints: {
        800: {
          perView: 3,
        },
        600: {
          perView: 2,
        },
      },
    }).mount();
  }
});
