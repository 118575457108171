import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['track', 'transcriptionContainer'];

  connect() {
    this.setFontSize();

    this.segmentsByTime = this.getSegmentsByTime();
  }

  setTrackCurrentTime(event) {
    const transcriptSegment = event.target;
    const track = this.trackTarget;

    track.addEventListener('seeked', () => {
      track.play();
    });

    track.currentTime = parseInt(transcriptSegment.dataset.segmentTime);
  }

  getFontSize() {
    return localStorage.getItem('fontSize') || 16;
  }

  setFontSize() {
    const fontSize = this.getFontSize();

    this.transcriptionContainerTarget.style.fontSize = `${fontSize}px`;
  }

  saveFontSize(fontSize) {
    localStorage.setItem('fontSize', fontSize);
  }

  increaseFontSize() {
    const fontSize = this.getFontSize();

    this.saveFontSize(parseInt(fontSize) + 1);
    this.setFontSize();
  }

  decreaseFontSize() {
    const fontSize = this.getFontSize();

    this.saveFontSize(parseInt(fontSize) - 1);
    this.setFontSize();
  }

  getSegmentsByTime() {
    const segments = this.transcriptionContainerTarget.querySelectorAll('.track-segment');

    return Array.from(segments).reduce((acc, segment) => {
      const segmentTime = parseInt(segment.dataset.segmentTime);

      return {
        ...acc,
        [segmentTime]: segment,
      };
    }, {});
  }
}
